import '../styles/globals.scss';
import '../styles/code-highlight.css';
import '../styles/ckeditor.css';
import '../styles/lexical.css';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { PageBaseProps, handleAndGetTenantAsync } from '../utils/tenants';
import NextNProgress from "nextjs-progressbar";
import { useCallback, useEffect, useMemo } from 'react';
import { ScriptProps } from 'next/script';
import { useRouter } from 'next/router';
import { isRtlLanguage } from '../utils/textTools';
import { NextPage } from 'next';
import { AppContext, useAppContext } from '../frontend/AppContext';
import clsx from 'clsx';
import { getLanguageName } from '../utils/languages';

type Page<P = Record<string, never>> = NextPage<P> & {
    Layout: (page: ScriptProps) => JSX.Element;
};

type Props = AppProps & PageBaseProps & {
    Component: Page;
};

const Noop = ({ children }: ScriptProps) => <>{children}</>;


function App({ Component, pageProps }: Props) {
    const typedPageProps = pageProps as PageBaseProps;
    const router = useRouter();
    const { asPath } = router
    const partialPath = asPath.split('/')
    const showFooter = partialPath[2] !== "article" && partialPath[2] !== "category"
    // const Layout = Component.getLayout || Noop;

    useEffect(() => {
        const handle = () => {
            if (typedPageProps.tenant?.scripts.onNavigate) {
                (new Function(typedPageProps.tenant?.scripts.onNavigate))();
            }
        }

        router.events.on('routeChangeComplete', handle);

        return () => {
            router.events.off('routeChangeComplete', handle);
        }
    }, []);

    const colors = `
        html {
        --primary-bg-color: ${typedPageProps.tenant?.colors.primaryColor};
        }
    `;

    return <AppContext.Provider value={{ tenant: pageProps.tenant, auth: pageProps.auth }}>
        <div className='flex flex-col h-full' dir={isRtlLanguage(typedPageProps && typedPageProps.tenant ? typedPageProps.tenant.currentLocale : 'en') ? 'rtl' : 'ltr'}>
            <Head>
                <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@6.0.0-beta3/css/all.min.css" />
                <style>{colors}</style>

                <script id="dynamic-scripts" dangerouslySetInnerHTML={{ __html: typedPageProps.tenant?.scripts.head || '' }}></script>

                {typedPageProps.tenant?.metaTags?.map(mt => <meta key={mt.name} name={mt.name} content={mt.content} />)}
            </Head>
            <NextNProgress color='var(--primary-bg-color)' height={2} options={{ showSpinner: false }} />

            <div className='grow'>
                <Component {...pageProps} />
            </div>

            {showFooter && <Footer /> }
            {/* {global.window && <ProductFruits projectCode="llxSxZ872jv0zvso" language="en" {...userInfo} />} */}

            <div dangerouslySetInnerHTML={{ __html: typedPageProps.tenant?.scripts.body || '' }}></div>
        </div>
    </AppContext.Provider>
}

export default App

type FooterProps = {
    slugs?: {slug:string, locale:string}[]
}
export function Footer(props:FooterProps) {

    const ctx = useAppContext();

    const router = useRouter()

    const locale = ctx?.tenant?.currentLocale ?? "en"

    const noFooter = useMemo(() => {
        if (router?.pathname?.includes("/article-preview")) {
            return true
        }

        return false;
    }, [])

    const handleLanguageChange = useCallback(
        (code: string) => {
            if (!code) {
                return;
            }

            const { pathname, asPath, query } = router

            // Append the lang_selector query parameter correctly
            let finalPath = '/';

            if (pathname.startsWith('/[language]')) {
                const partialPath = asPath.split('/')
                if (props.slugs) {
                    const newSlug = props.slugs.find(x => x.locale == code)
                    if (newSlug){
                        partialPath[3] = newSlug.slug
                    }
                }
                const path = `/${code}/${partialPath.slice(2).join('/')}`;

                const url = new URL(window.location.origin + path);
                url.searchParams.set('lang_selector', 'true');

                for (const [key, value] of Object.entries(query)) {
                    if (key !== 'language') {
                        url.searchParams.set(key, value as string);
                    }
                }
                finalPath = url.pathname + url.search;
            }

            router.push(finalPath)
        },
        [router],
    )

    if (noFooter)
        return <></>

    return <div className='footer relative p-5 w-full mt-10 text-slate-300 z-10 kb-footer flex'>
        <div className='flex flex-col  mx-auto items-center space-y-2'>
            {
                ctx?.tenant?.supportedLanguages && ctx.tenant.supportedLanguages.length > 1 &&
                <div className='bg-primary-text text-primary-bg flex flex-row space-x-2 mx-auto text-sm'>
                    <div className="dropdown inline-block relative group cursor-pointer">

                        <div className='flex flex-row space-x-2'>

                            <i className={clsx("fa-solid fa-earth-americas text-xl group-hover:drop-shadow-xl cursor-pointer",
                                "bg-primary-text opacity-70 group-hover:opacity-100 transition-all duration-500 rtl:ml-2")}>
                            </i>
                            <div className='flex my-auto'>{getLanguageName(locale)}</div>

                        </div>

                        <ul className={clsx("dropdown-menu text-md absolute hidden ml-12 -mt-3",
                            "-translate-y-full -translate-x-1/2 rtl:translate-x-1/3 text-gray-700 pt-1 p-2 w-52",
                            "rounded-sm shadow-md z-10 bg-white")}>
                            {
                                ctx?.tenant?.supportedLanguages.filter(e => e != null).map((code, index) => {
                                    return <li key={index} className="p-2 bg-white rounded-sm select-none cursor-pointer">
                                        <a onClick={(e) => { e.preventDefault(); handleLanguageChange(code) }}
                                            className="hover:bg-gray-100 py-1 px-2 mx-auto block whitespace-no-wrap">
                                            <span className='flex truncate overflow-hidden break-all'>
                                                {
                                                    getLanguageName(code)
                                                }
                                            </span>
                                        </a>
                                    </li>
                                })
                            }
                        </ul>
                    </div>

                </div>
            }
            <div className='mx-auto max-w-5xl flex flex-row items-center text-sm'>
                Powered by <a className='pl-1' href="https://productfruits.com">Product Fruits</a>
            </div>
        </div>
    </div>
}
