import { stripHtml } from "string-strip-html";

export function htmlToText(html: string) {
    try {
        return stripHtml(html).result || '';
    } catch (e) {
        return html;
    }
}

export function extractPerex(content: string) {
    if (content) {
        const text = htmlToText(content);
        const part = text.split(' ').slice(0, 21)
        const extractedPart = part.slice(0, 20);

        return extractedPart.join(' ') + (part.length >= extractedPart.length ? '...' : '');
    }
}

export function isRtlLanguage(lng: string) {
    const rtlLanguages = ['ar',
        'arc',
        'dv',
        'fa',
        'ha',
        'he',
        'khw',
        'ks',
        'ku',
        'ps',
        'ur',
        'yi'];

    return rtlLanguages.indexOf(lng) !== -1;
}