import { createContext, useContext } from 'react';
import { TenantData } from '../backend/queries/tenants';
import { Auth } from '../backend/models';

export const AppContext = createContext<{
    tenant: TenantData
    auth?: Auth
} | null>(null);

export const useAppContext = () => useContext(AppContext);


export const useAuthContext = () => {

    const ctx = useAppContext();

    const isAuthEnabled = ctx?.auth?.isAnyMethodEnabled || ctx?.auth?.methods.isHmacEnabled

    const auth = ctx?.auth

    const user = ctx?.auth?.user

    return { isAuthEnabled, auth, user }

}
